<script>
  import { createEventDispatcher, tick } from "svelte";
  import { onMount } from "svelte";
  import ChatTextBox from "./chat/ChatTextBox.svelte";
  import ChatFileUploadBox from "./chat/ChatFileUploadBox.svelte";
  import ChatCheckBox from "./chat/ChatCheckBox.svelte";
  import ChatSelect from "./chat/ChatSelect.svelte";
  import ChatSelectWithButtons from "./chat/ChatSelectWithButtons.svelte";
  import ChatBubbleBot from "./chat/ChatBubbleBot.svelte";
  import ChatBubbleUser from "./chat/ChatBubbleUser.svelte";
  import ChatBubbleBotFreeText from "./chat/ChatBubbleBotFreeText.svelte";
  import TelField from "./base/TelField.svelte";
  import Button from "./base/Button.svelte";
  import Closer from "./base/Closer.svelte";
  import Typography from "./base/Typography.svelte";
  import { sendform, fileUpload } from "../utils/sendwhatsapp_rewrite_chabi";
  import Spinner from "./base/Spinner.svelte";
  import { isClosedConversationStore } from "../store";
  import ChatGenderSelector from "./chat/ChatGenderSelector.svelte";
  import ChatDatePicker from "./chat/ChatDatePicker.svelte";
  import ChatTimePicker from "./chat/ChatTimePicker.svelte";

  export let data;
  export let activePerson;
  export let isBigperson = false;

  let sent = false;
  let err = false;
  let index = 0; // Index of the current form item
  let height;
  let screenHeight;
  let isLoading = false;
  let cookieModalExist = eval("typeof modalPrivacityTermsCookies") === "function";

  $: formitems = activePerson.form.items;
  $: buyLinks = activePerson.form.buyLinks;
  $: type = "text";
  $: hasBuyLinks = (sent === true || isSent()) && buyLinks && buyLinks.length > 0;
  $: fullScreenMobile = data.expandFullScreenMobile;
  $: fullScreenMobileStyle = fullScreenMobile && (screenHeight > height ? "height:100%;" : "height:max-content;");
  $: notQualified = false;
  $: sendMessage = data.transSuccessMessage ?? "Your message has been sent";
  $: closeConversationIndex = -1;

  onMount(() => {
    formitems.forEach((item) => {
      if (item.val) index++;
    });
    type = formitems[index]?.type;
  });

  const dispatch = createEventDispatcher();

  function handleClick() {
    dispatch("close");
  }

  const showBot = (i, bubble) => {
    if (closeConversationIndex === i) {
      return false;
    }
    const activeItems = formitems.slice(0, i).filter((item) => item.type !== "hidden" && item.filtered !== true);
    if (i == 0) {
      return true;
    } else if (activeItems[activeItems.length - 1].val) {
      return true;
    }
    return false;
  };

  const showDot = (i) => {
    if (formitems[i].val) {
      return false;
    }
    return true;
  };

  const showUser = (i, bubble) => {
    if (formitems[i].val && bubble.type !== "freetext") {
      return true;
    }
    return false;
  };

  const isSent = () => {
    const activeItems = formitems.filter((item) => item.type !== "freetext" && item.type !== "hidden");
    if (activeItems[activeItems.length - 1].val) {
      return true;
    }
    return false;
  };

  const setVal = async (v) => {
    let objDiv = document.getElementById("messageBox");
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
    index = formitems.findIndex((obj) => !obj.val && obj.filtered !== true);
    let val;
    if (type == "tel") {
      val = v.detail.num === "" ? "" : v.detail.code + v.detail.num;
    } else if (type != "file") {
      val = v.detail.trim();
    } else {
      val = v.detail;
      formitems[index].name = val.name;
    }

    let required = activePerson.form.items[index].required;
    if (type === "checkbox") {
      required = required && activePerson.form.items[index].legalUrl;
    }

    if (!validate(type, type === "tel" ? v.detail : val, required, type === "zipcode" ? formitems[index].validation : null)) return false;

    formitems[index].val = val === "" ? " " : val;

    formitems.forEach((item, i) => {
      if (item.fieldToFilterFrom) {
        const values = item.valueToFilterFrom.split("|");

        const checkItem = formitems[item.fieldToFilterFrom - 1];
        let isFiltered = true;
        values.forEach((value) => {
          if (checkItem.type === "checkbox") {
            if ((checkItem.val === "Yes" && value === "1") || (checkItem.val === "No" && value !== "1")) {
              isFiltered = false;
            }
          } else if (checkItem.val && value.trim() === checkItem.val.trim()) {
            isFiltered = false;
          }
        });
        formitems[i].filtered = isFiltered;
      }
    });

    if (!isLastSend(index, formitems)) {
      index = formitems.findIndex((obj) => !obj.val && obj.type !== "hidden" && obj.filtered !== true);
      updateProgressBar(index);
      type = formitems[index].type;
      scrollToBottom();
    } else {
      // Check if there are any file uploads and upload them
      for (let i in formitems) {
        if (formitems[i].type === "file" && formitems[i].val) {
          isLoading = true;
          const res = await fileUpload(formitems[i].val, activePerson.id);
          formitems[i].val = res?.data?.id;
        }
      }

      sent = true;
      if (!buyLinks || buyLinks.length === 0) {
        let scoreCnt = 0,
          scoreSum = 0,
          score;
        if (activePerson.form.activateScoringFilter) {
          for (let item of formitems) {
            if (item.type === "select") {
              score = item.dataObject.find((obj) => obj.value === item.val).score;
              switch (score) {
                case "gold":
                  scoreSum += 3;
                  break;
                case "silver":
                  scoreSum += 2;
                  break;
                case "bronze":
                  scoreSum += 1;
                  break;
              }
              scoreCnt++;
            }
          }
        }
        notQualified =
          (scoreCnt > 0 && scoreSum / scoreCnt < 2) ||
          (activePerson.online === false && data.offlineLeads === true) ||
          (data.desktopLeads === true && window.innerWidth > 1000);

        if (closeConversationIndex > -1) {
          notQualified = false;
        }

        for (let i in activePerson.form.items) {
          if (activePerson.form.items[i].type === "hidden") {
            activePerson.form.items[i].val = activePerson.form.items[i].value ?? activePerson.form.items[i].hval;
          }
        }

        sendform(
          activePerson,
          {
            detail: { data: combineFormsAndRemoveDuplicates(formitems, activePerson.form), notQualified },
          },
          data.redirectUrl
        );
      }
    }

    if (objDiv) {
      setTimeout(() => {
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 300);
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  };

  /**
   * Combines form items and removes duplicates.
   *
   * @param {Array} formitems - Array of form items.
   * @param {Object} activePersonForm - Active person form object.
   * @returns {Array} - Array of unique form items.
   */
  const combineFormsAndRemoveDuplicates = (formitems, activePersonForm) => {
    let toSendArray = [...formitems.filter((item) => item.type !== "freetext"), ...activePersonForm.items.filter((item) => item.type === "hidden")];
    const uniqueSet = new Set(toSendArray);
    return [...uniqueSet];
  };

  const isLastSend = (i, payload) => {
    const nextItems = formitems.slice(i + 1).filter((item) => item.type !== "freetext" && item.type !== "hidden" && item.filtered !== true);
    const option = formitems[i].dataObject.find((obj) => obj.value === formitems[i].val);
    if (nextItems.length === 0) {
      if (option?.closeConversation) {
        sendMessage = option.closeConversationMessage;
        isClosedConversationStore.set(true);
      }
      return true;
    }
    if (formitems[i].type === "select") {
      if (option?.closeConversation) {
        sendMessage = option.closeConversationMessage;
        closeConversationIndex = i + 1;
        isClosedConversationStore.set(true);
        return true;
      }
    }
    return false;
  };

  const validate = (type, value, isRequired, validation) => {
    if (value === "") {
      if (!isRequired) return true;
      err = data.requiredValidationText ? data.requiredValidationText : "This needs to be filled in.";
      return false;
    }
    if (type === "checkbox" && isRequired && value === "No") {
      err = data.requiredValidationText ? data.requiredValidationText : "This needs to be confirmed.";
      return false;
    }
    if (type === "email") {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        err = data.emailValidationText ? data.emailValidationText : "Sorry but this email is not valid.";
        return false;
      }
    } else if (type === "tel") {
      if (!/^\d{6,15}$/.test(value.num) || (value.code === "34" && !/^[6-8]\d{8}$/.test(value.num) && !/^[9]\d{8,9}$/.test(value.num))) {
        err = data.telValidationText ? data.telValidationText : "This is not a phone number.";
        return false;
      }
    } else if (type === "number") {
      if (!/^\d+$/.test(value)) {
        err = data.numberValidationText ? data.numberValidationText : "This is not a number.";
        return false;
      }
    } else if (type === "url") {
      if (!/(http(s)?:\/\/)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(value)) {
        err = data.urlValidationText ? data.urlValidationText : "Please use a https:// or http:// URL.";
        return false;
      }
    } else if (type === "file") {
      if (value.size > 5368709120 || !["pdf", "docx", "xlsx", "jpg", "jpeg", "png", "zip"].includes(value.name.split(".").pop().toLowerCase())) {
        err = data.fileValidationText ? data.fileValidationText : "Sorry but this file is not valid.";
        return false;
      }
    } else if (type === "zipcode") {
      if (validation) {
        let regExpPattern = validation.replace(/^\/|\/$/g, "");
        let regExp = new RegExp(regExpPattern);

        if (!regExp.test(value)) {
          err = data.zipcodeValidationText ? data.zipcodeValidationText : "This zipcode is not valid.";
          return false;
        }
      }
    }
    err = false;
    return true;
  };

  const sendtocallme = (url = null) => {
    dispatch("callmex", {
      data: formitems,
      url: url,
      notQualified: notQualified,
    });
  };

  const handleParse = (text, i) => {
    const words = text.match("{{([^}]+)}}");
    if (words === null) {
      return text;
    }
    const index = words[1].includes("answer_") ? words[1].split("answer_")[1] : -1;
    const parsedLabel = text.replace(words[0], parseInt(index) > 0 ? formitems[index - 1].val : "");
    formitems[i].label = parsedLabel;
    return parsedLabel;
  };

  /**
   * Updates the width of the progress bar based on the current index.
   * @param {number} index - The current position in the formitems array.
   */
  const updateProgressBar = (index) => {
    const progress = (index / formitems.length) * 100;
    const progressBar = document.getElementById("chatwith-chatform-progress-bar");
    if (progressBar) {
      progressBar.style.width = progress + "%";
    }
  };

  /**
   * Scrolls to the bottom of the chat box.
   */
  const scrollToBottom = () => {
    tick().then(() => {
      let nodes = document.querySelectorAll(".chatwith-bubble-bot");
      let lastMessage = nodes[nodes.length - 1];
      if (lastMessage) {
        lastMessage.scrollIntoView({ behavior: "smooth" });
      }
    });
  };
</script>

<svelte:window bind:innerHeight={screenHeight} />
<div class="chatwith-chat" class:fullScreenMobile class:hasBuyLinks class:notQualified>
  <div class="chatwith-chat-panel" bind:clientHeight={height} style={fullScreenMobileStyle}>
    {#if data.widgetType !== "bigperson"}
      <Closer on:close={handleClick} />
    {/if}
    <div class="chatwith-chat-messages" id="messageBox">
      {#each formitems as bubble, i}
        {#if bubble.type !== "hidden" && (!bubble.fieldToFilterFrom || bubble.filtered === false)}
          {#if showBot(i, bubble) === true}
            <div class={showDot(i) && "scale-in-left"}>
              <div class="chatwith-chat-msg-header">{activePerson.name}</div>
              {#if bubble.type === "freetext"}
                <ChatBubbleBotFreeText text={bubble.hval} showDot={showDot(i)} on:message={setVal} />
              {:else}
                <ChatBubbleBot text={handleParse(bubble.label, i)} showDot={showDot(i)} />
              {/if}
            </div>

            {#if bubble.legalUrl}
              <div class="checkbox-link scale-in-left">
                {#if isBigperson && bubble.legalCanUseLocalModal && cookieModalExist}
                  {@html `<a target="_blank" onClick="return modalPrivacityTermsCookies('privacityPopup');">${
                    bubble.legalUrlText ?? "Click here to see the Privacy Policy"
                  }</a
                >`}
                {:else}
                  <a target="_blank" href={bubble.legalUrl}>{bubble.legalUrlText ?? "Click here to see the Privacy Policy"}</a>
                {/if}
              </div>
            {/if}
          {/if}
          {#if showUser(i, bubble) === true}
            <div class="scale-in-right">
              <ChatBubbleUser bubbleData={bubble} />
            </div>
          {/if}
        {/if}
      {/each}
    </div>
  </div>
  <div class="chatwith-chat-footer {data.widgetType === 'bigperson' ? '' : 'fixed-bottom'}">
    {#if sent === true || isSent()}
      {#if buyLinks && buyLinks.length > 0}
        {#each buyLinks as item, i}
          <Button
            id={"chatwith-buy-button" + i}
            on:click={() => sendtocallme(item.link)}
            buttonText={item.buttontext}
            showIcon={true}
            iconUrl={data.whatsappIconUrl}
          />
        {/each}
      {:else}
        <div class="chatwith-chat-sent-message">
          {sendMessage}
        </div>
      {/if}
    {:else}
      {#if err}<Typography text={err} error={true} />{/if}

      {#if isLoading}
        <Spinner />
      {:else if type == "checkbox"}
        <ChatCheckBox defaultLanguage={data.defaultLanguage} on:message={setVal} />
      {:else if type === "select" || type === "selectAndPrice"}
        {#if formitems[index].showAsButton}
          <ChatSelectWithButtons on:message={setVal} options={formitems[index]} currency={data?.currency} />
        {:else}
          <ChatSelect on:message={setVal} options={formitems[index]} currency={data?.currency} />
        {/if}
      {:else if type === "tel"}
        <TelField on:submit={setVal} {data} showButton={true} class="chatwith-outer-telfield" />
      {:else if type === "file"}
        <ChatFileUploadBox on:message={setVal} hasBorder={data.widgetType === "bigperson"} fullScreenMobile={data.expandFullScreenMobile} />
      {:else if type === "gender"}
        <ChatGenderSelector
          options={formitems[index].dataObject}
          label={formitems[index].label}
          showWritten={formitems[index].showWritten}
          on:message={setVal}
        />
      {:else if type === "date"}
        <ChatDatePicker placeholder={formitems[index].label} on:message={setVal} />
      {:else if type === "time"}
        <ChatTimePicker placeholder={formitems[index].label} on:message={setVal} />
      {:else}
        <ChatTextBox on:message={setVal} {type} fullScreenMobile={data.expandFullScreenMobile} translateChatAnswer={data.translateChatAnswer} />
      {/if}
    {/if}
  </div>
</div>
